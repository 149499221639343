















































import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Observer
@Component({})
export default class CharacterBody extends Vue {
  @Prop({ default: 4 }) colWidth!: number;
  @Prop({ default: false }) showRare!: boolean;
  @Prop({ default: 40 }) iconSize!: number;
}
